import React from "react";
import {
	Backdrop,
	Button,
	Box,
	Card,
	CardMedia,
	CardContent,
	CardActions,
	Container,
	Divider,
	Grow,
	Modal
} from "@mui/material";
import { HeaderBox, SubHeaderBox, ContentBox } from ".";
import Img from "../Media/DefaultImg.jpg";

function ProductModal({ product }) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute",
		top: "10%",
		left: "10%",
		width: "80%",
		height: "80%",
		bgcolor: "background.paper",
		borderRadius: "25px",
		boxShadow: 24,
		overflow: "hidden"
	};
	const cardStyle = {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%"
	};

	return (
		<>
			<Button
				variant="outlined"
				size="small"
				onClick={handleOpen}
				sx={{ width: "100%" }}
			>
				More Detail
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500 }}
			>
				<Grow in={open}>
					<Box sx={style}>
						<Card sx={cardStyle}>
							<ModalHeader
								title={product.title}
								subtitle={product.content}
							/>
							<Divider variant="fullWidth" />
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									height: "100%",
									width: "100%"
								}}
							>
								<ModalImage
									image={product.image}
									title={product.title}
								/>
								<ModalContent content={product.description} />
							</Box>
							<Divider variant="fullWidth" />
							<ModalActions />
						</Card>
					</Box>
				</Grow>
			</Modal>
		</>
	);
}

const ModalHeader = ({ title, subtitle }) => {
	return (
		<Container>
			<HeaderBox large content={title} />
			<SubHeaderBox large content={subtitle} />
		</Container>
	);
};

const ModalImage = ({ image, title }) => {
	return (
		<CardMedia
			component="img"
			image={image ? image : Img}
			alt={`${title} image`}
			sx={{
				height: 300,
				width: 300,
				border: "solid #0003",
				borderRadius: "10px",
				margin: "15px"
			}}
		/>
	);
};

const ModalContent = ({ content }) => {
	return (
		<CardContent sx={{ width: "100%", height: "100%" }}>
			<SubHeaderBox content="Product Details" sx={{fontSize:'larger',marginBottom:'10px',borderBottom:'2px solid #0003'}} />
			<ContentBox content={content} />
		</CardContent>
	);
};

const ModalActions = () => {
	return (
		<CardActions>
			<Button>Button</Button>
		</CardActions>
	);
};

export { ProductModal };

import React from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardActions,
	CardMedia,
	CardContent,
	Link,
	Paper
} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import Img from "../Media/DefaultImg.jpg";
import {
	useBasket,
	AddOneToBasket,
	ProductModal,
	ProductList,
	ProductShortList
} from ".";

function Products(props) {
	return <Box {...props}>{props.children}</Box>;
}

const Product = (props) => {
	// const [hover, setHover] = React.useState(false);
	const { id, title, content, image, price } = props;
	const product = { id: id, title: title };

	const paperStyle = {
		width: "fit-content",
		// transform: `scale(${hover ? 0.95 : 0.85})`,
		transform: "scale(0.85)",
		boxShadow: 1,
		m: "auto",
		transitionProperty: "all",
		borderRadius: "5px",
		overflow: "hidden",
		":hover": {
			boxShadow: 6,
			transform: "scale(0.95)"
		}
	};

	return (
		<Paper /* elevation={hover ? 6 : 1}*/ sx={paperStyle}>
			<Card
				// onMouseEnter={() => setHover(true)}
				// onMouseLeave={() => setHover(false)}
				{...props}
			>
				<CardImage image={image} title={title} />
				<CardText title={title} content={content} />
				<ProductActions id={product.id} /* setHover={setHover()}*/ />
			</Card>
			<Box>
				<p
					style={{
						display: "inline",
						fontSize: "130%",
						fontWeight: "bold"
					}}
				>{`R ${price}.00 `}</p>
				incl. VAT
			</Box>
		</Paper>
	);
};

const productsArray = ProductList.map((product) => {
	const { id, title, content, image, price } = product;

	return (
		<Product
			key={id}
			id={id}
			title={title}
			content={content}
			image={image}
			price={price}
			sx={{ height: "fit-content", width: "300px", padding: "5px" }}
		/>
	);
}, ProductList);
const productsArrayshort = ProductShortList.map((product) => {
	const { id, title, content, image, price } = product;

	return (
		<Product
			key={id}
			id={id}
			title={title}
			content={content}
			image={image}
			price={price}
			sx={{ height: "fit-content", width: "300px" }}
		/>
	);
}, ProductShortList);

const ProductActions = ({ id /*,setHover*/ }) => {
	const { ProductsData } = useBasket();
	const currentProduct = ProductsData.filter((p) => p.id === id)[0];
	const Qty = currentProduct.basketQty || 0;

	return (
		<CardActions>
			<ButtonGroup sx={{ width: "100%" }}>
				<ProductModal
					product={currentProduct} /* onClick={ () => setHover(false) }*/
				/>
				<Button
					onClick={() => AddOneToBasket(id)}
					variant="contained"
					size="small"
					sx={{ width: "100%" }}
				>
					{Qty > 0 ? "Add 1 More" : "Add to Basket"}
				</Button>
			</ButtonGroup>
		</CardActions>
	);
};
const CardImage = ({ image, title }) => {
	return (
		<CardMedia
			component="img"
			image={image ? image : Img}
			alt={`${title} image`}
			sx={{ height: 200, minWidth: "100%" }}
		/>
	);
};
const CardText = ({ title, content }) => {
	return (
		<CardContent>
			<h3>{title}</h3>
			<p>{content}</p>
		</CardContent>
	);
};

const ProductBox = ({ singleline }) => {
	const layout = {
		display: "flex",
		height: "100%",
		maxHeight: singleline ? "700px" : null,
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap",
		overflow: "hidden"
	};
	return (
		<Box
			sx={{
				display: "block",
				maxHeight: "fit-content",
				width: "95%"
			}}
		>
			<h1>Product Range</h1>
			<Products singleline={singleline} sx={layout}>
				{singleline ? productsArrayshort : productsArray}
			</Products>
			{singleline ? (
				<Link
					component={RouteLink}
					variant="h5"
					to="/Shop"
					underline="hover"
				>
					View full Range
				</Link>
			) : null}
		</Box>
	);
};

export { ProductBox };

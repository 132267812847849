const ProductShortList = [
	{
		id: "ID-0001",
		title: "Product1",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0002",
		title: "Product2",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0003",
		title: "Product3",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0004",
		title: "Product4",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0005",
		title: "Product5",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0006",
		title: "Product6",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0007",
		title: "Product7",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0008",
		title: "Product8",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	}
];

const ProductList = [
	...ProductShortList,
	{
		id: "ID-0009",
		title: "Product9",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0010",
		title: "Product10",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0011",
		title: "Product11",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0012",
		title: "Product12",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0013",
		title: "Product13",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0014",
		title: "Product14",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0015",
		title: "Product15",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0016",
		title: "Product16",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	},
	{
		id: "ID-0017",
		title: "Product17",
		content: "Product Discription",
		description: "fullDescription",
		price: 10,
		basketQty: 0
	}
];

const userData = [
	{ Avatar: "companyLogo" },
	{ Company: "Company" },
	{ CompanyEmail: "@Company" },
	{ ContactName: "Contact Name" },
	{ ContactEmail: "Contact Email" },
	{ Number: "Contact Number" },
	{ History: "Company History or ContactHistory" },
	{ HistoryType: "Company or ContactList" }
];

export { ProductList, ProductShortList, userData };

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'

const root = document.getElementById('root')
const render = <BrowserRouter><React.StrictMode><App /></React.StrictMode></BrowserRouter>
// ReactDOM.render(
//   <BrowserRouter>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </BrowserRouter>,
//   root
// );
ReactDOM.createRoot(root).render(render)

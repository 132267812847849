import {
	AppBar,
	colors,
	Slide,
	Toolbar,
	useMediaQuery,
	useScrollTrigger
} from "@mui/material";
import { LinkBox, ProfileBox, TitleBox } from ".";
import { Link } from "react-router-dom";

function HideOnScroll (props) {
	return (
		<Slide direction="down" in={ !useScrollTrigger({ threshold: 50 }) }>
			{ props.children }
		</Slide>
	);
}

const TitleBar = (props) => {
	const smallScreen = useMediaQuery("(max-width:665px)");
	const mobileScreen = useMediaQuery("(max-width:450px)");

	return (
		<>
			<HideOnScroll>
				<AppBar
					sx={ {
						maxHeight: 60,
						boxShadow: "none",
						overflow: 'hidden'
					} }
				>
					<Toolbar disableGutters sx={ { width: "100%", alignItems: 'flex-end', minHeight: '60px !important', backgroundColor: colors.cyan[900] } }>
						<LinkBox
							Links={ ["Home"/*, "Shop"*/, 'About', "Contact"] }
							small={ smallScreen }
						/>
						<Link to="/" style={ { display: 'contents', color: 'inherit' } }><TitleBox small={ smallScreen } mobile={ mobileScreen } /></Link>
						{ !smallScreen && <ProfileBox /> }
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar disableGutters sx={ { width: '100%', height: '100%', flexDirection: 'column', padding: '60px 0 0 0 !important' } }>
				{ props.children }
			</Toolbar>
		</>
	);
};

export { TitleBar }

import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Divider } from "../Components";

const Home = lazy(() => import("./Homepage"))
const ContactPage = lazy(() => import('./ContactPage'))
const AboutPage = lazy(() => import('./AboutPage'))
// const Shop = lazy(()=>import("./Shop"))

const HomePage = <><Home />
	<Divider />
	<AboutPage />
	<Divider />
	<ContactPage /></>

export default function PageContent () {
	return (
		<Suspense fallback={ <h3>Loading ...</h3> }>
			<Routes>
				<Route path="/" element={ HomePage } />
				<Route path="/About" element={ <AboutPage /> } />
				<Route path="/Contact" element={ <ContactPage /> } />
				{/* <Route path="/Shop" element={<Shop/>}/> */ }
				<Route
					path="*"
					element={
						<p>
							<strong>404 </strong> Not Found
						</p>
					}
				/>
			</Routes>
		</Suspense>
	);
}

import logo from "../Media/Logo.webp";
import med1 from "../Media/Med1.webp";
import med1Alt from "../Media/Med1Alt.webp";
import med2 from "../Media/Med2.webp";
import medSmall from "../Media/MedSmall.webp";
import defaultImage from "../Media/DefaultImg.jpg"
import { ParallaxBox } from "./Boxes";
import { Box } from "@mui/material";

const DefaultImage = () => {
	return <img src={ defaultImage } alt="Empty" />
}

const Logo = () => {
	return <img src={ logo } alt="Logo Icon" className="Logo" />;
};

function MedImage1 () {
	const X = Math.random() < 0.5 ? 0 : 100
	// const { state, setState } = useState((x = X) => ({ 1: x, 2: x + 100 }))

	// function updateImage (x = state) { setTimeout(() => setState(state == 0 ? -100 : 0), 5 * 60 * 1000).refresh() }

	// const rabbitDownKeyframes = new KeyframeEffect(
	// 	whiteRabbit, // element to animate
	// 	[
	// 	  { transform: "translateY(0%)" }, // keyframe
	// 	  { transform: "translateY(100%)" }, // keyframe
	// 	],
	// 	{ duration: 3000, fill: "forwards" }, // keyframe options
	// //  );
	// var keyframes = new KeyframeEffect(null, [{ transform: "translateX(0%)" }, { transform: 'translateX(-100%)' }], { duration: "auto", endDelay: 5, direction: "alternate", fill: "both" });

	// let animation = new Animation(keyframes)
	// document.querySelectorAll("#ParalaxImg").forEach(each => each.animate(keyframes).play())

	const getImages = async () => await document.querySelectorAll("ParalaxImg");

	function toggleTransition (force) {
		getImages().then(all => all.forEach((each) => { each.toggleAttribute("translate", force); return console.log(each.getAttribute('translate')); }))
	}


	// return <Box style={ { display: 'flex', width: '100%', height: '100%', overflow: 'hidden' } } >
	// 	<img id="ParalaxImg" src={ med1 } alt="Image of Medical Equipment." Animation={ animation } style={ { animationPlayState: "running", width: '100%', margin: '0 25px', objectFit: 'cover' } } />
	// 	<img id="ParalaxImg" src={ med1Alt } alt="Image of medical equipment." style={ { width: '100%', margin: '0 25px', objectFit: "cover" } } />
	// </Box >;
	return <Box style={ { display: 'flex', width: '100%', height: '100%', overflow: 'hidden' } } >
		<img onLoad={ toggleTransition(true) } onTransitionEnd={ toggleTransition() } className="ParalaxImg" src={ med1 } alt="Image of Medical Equipment." style={ { "--index": 0 } } />
		<img className="ParalaxImg" src={ med1Alt } alt="Image of medical equipment." style={ { "--index": 1 } } />
	</Box >;
};

function MedImage2 () {
	return <img aria-hidden src={ med2 } alt="Decorating Image" style={ { height: '100%', objectFit: 'cover' } } />;
};

function ParalaxImg ({ small }) {
	// var image = small ? medSmall : med1
	return <ParallaxBox sx={ { borderRadius: '10px', backgroundImage: small && `url(${medSmall})` } } />
}


export { DefaultImage, Logo, MedImage1, MedImage2, ParalaxImg };
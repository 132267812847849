import "./App.css";
import { Container } from "@mui/material";
import { TitleBar/*, FooterBox*/, ProductList, useBasket } from "./Components";
import PageContent from "./Pages/PageContent";

export default function App() {
	useBasket.setState({ ProductsData: ProductList });

	return (
		<Container className="App" sx={{height:'100%'}}>
			<TitleBar>
				<PageContent />
				{/* <FooterBox>Page Footer</FooterBox> */}
			</TitleBar>
		</Container>
	);
}

import { Facebook } from "@mui/icons-material";
import { Box, Divider, Link } from "@mui/material";
import React from "react";
import { NavLink as RouteLink } from "react-router-dom";
import {
	/*useBasket, BasketButton,*/ Logo,
	MedImage2 /*, ProfileButton*/,
} from ".";

const FloatBox = (props) => {
	/**
	 * @default align=center
	 * to align left or right
	 * add left or right to props
	 */
	const alignment = props.right ? "right" : props.left ? "left" : "center";

	const style = {
		display: "block",
		width: "100%",
		minWidth: "fit-content",
		height: "100%",
		textAlign: alignment,
		alignItems: alignment,
		background: "transparent",
		margin: 0,
		...props.sx,
	};
	return <Box sx={ style }>{ props.children }</Box>;
};

const LinkBox = ({ Links, small }) => {
	const children = Links.map((child, index) => {
		return (
			<Link
				component={ RouteLink }
				color="inherit"
				underline="hover"
				key={ child }
				to={ `/${child === "Home" ? "" : child}` }
				className="MyNavLink"
			>
				{ child }
			</Link>
		);
	});

	return (
		<FloatBox left={ !small } right={ small } sx={ { order: small ? 2 : 1 } }>
			{ children }
		</FloatBox>
	);
};

const TitleBox = ({ small, mobile }) => {
	return (
		<Box
			sx={ {
				display: "flex",
				height: "60px",
				// width: "100%",
				flexDirection: "column",
				flexWrap: "wrap",
				alignContent: small ? "left" : "center",
				order: small ? 1 : 2,
			} }
		>
			<Logo />
			{/* <h1>{ small ? "CM" : "Centralmed" }</h1> */ }
			{ small ? <h2>{ mobile ? "CM" : "Centralmed" }</h2> : <h1>Centralmed</h1> }
		</Box>
	);
};

const ContentBox = (props) => {
	/**
	 * header-@default:h3 | Large:h1 | Small:h5
	 * subheader-@default:h4 | Large:h2 | Small:h6
	 * content-@default:body-<p>
	 */
	const dividerStyle = props.divider;
	return (
		<Box style={ props.sx }>
			<HeaderBox
				large={ props.large }
				small={ props.small }
				content={ props.header }
				sx={ props.hStyle }
			/>
			<SubHeaderBox
				large={ props.large }
				small={ props.small }
				content={ props.subHeader }
				sx={ props.shStyle }
			/>
			<Box>{ props.content || props.children }</Box>
			{ props.divider ? <Divider light { ...dividerStyle } /> : null }
		</Box>
	);
};

const HeaderBox = (props) => {
	/**
	 * @default:h3
	 * large:h1
	 * small:h5
	 */
	const style = props.sx;
	return props.large ? (
		<h1 style={ style }>{ props.content || props.children }</h1>
	) : props.small ? (
		<h5 style={ style }>{ props.content || props.children }</h5>
	) : (
		<h3 style={ style }>{ props.content || props.children }</h3>
	);
};

const SubHeaderBox = (props) => {
	/**
	 * @default:h4
	 * large:h2
	 * small:h6
	 */
	const style = props.sx;
	return props.large ? (
		<h2 style={ style }>{ props.content || props.children }</h2>
	) : props.small ? (
		<h6 style={ style }>{ props.content || props.children }</h6>
	) : (
		<h4 style={ style }>{ props.content || props.children }</h4>
	);
};

const ContactBox = ({ mobile }) => {
	return (
		<Box
			sx={ {
				display: "flex",
				
				justifyContent: mobile ? "flex-start" : 'center',
				margin: "25px",
				width: "95%",
				height: "fit-content",
				borderRadius: "10px",
				flexDirection: mobile && "column",
			} }
		>
			<Box
				m="0px"
				sx={ {
					display: "grid",
					// width: "50%",
					background: "lightgrey",
					borderRadius: 0,
					borderTopLeftRadius: "inherit",
					borderTopRightRadius: mobile && "inherit",
					borderBottomLeftRadius: !mobile && "inherit",
					a: { color: "#00e" },
				} }
			>
				<HeaderBox content="Contact Information" />
				<p style={ { padding: "1em" } }>
					E-mail
					<br />
					<a href="mailto:sales@centralmed.co.za">
						sales@centralmed.co.za
					</a>
				</p>
				<p>
					Call Us
					<br />
					<a href="tel:+27606862189">060 686 2189</a>
				</p>
				{/* <p>{ "Pretoria-West ,Pretoria ,South-Africa" }</p>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28750.503440221386!2d28.142485959747574!3d-25.74369924703377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9562878b02b9b1%3A0x7aea05ced1a687f4!2sPretoria%20West%2C%20Pretoria%2C%200183!5e0!3m2!1sen!2sza!4v1671462126910!5m2!1sen!2sza" width="100%" height="300" style={ { border: 0, padding: '1em' } } allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
				{/* <a aria-label="View larger map" target="_blank" rel="noreferrer" jstcache="20" href="https://maps.google.com/maps?ll=-25.743739,28.159996&amp;z=12&amp;t=m&amp;hl=en&amp;gl=ZA&amp;mapclient=embed&amp;q=Pretoria%20West%20Pretoria%200183" jsaction="mouseup:placeCard.largerMap" style={ { padding: '1em' } }>Pretoria-West ,Pretoria ,South-Africa</a> */ }
				<p style={ { padding: "1em" } }>
					Address
					<br />
					<a
						aria-label="View larger map"
						target="_blank"
						rel="noreferrer"
						href="https://goo.gl/maps/6adXQzkGXzWTxBjZ6"
					>
						Pretoria-West ,Pretoria ,South-Africa
					</a>
				</p>
				<Box background="white">
					<SubHeaderBox>Social Links</SubHeaderBox>
					<a
						href="https://www.fb.com/100089876362154"
						target="_blank"
						rel="noreferrer"
					>
						Find us on{ " " }
						<Facebook style={ { transform: "translate(-2.5px,5px)" } } />
					</a>
				</Box>
				{/* <p style={ { padding: '1em 5%', textAlign: 'start' } }><b>Reg nr.:</b> 2020/489385/07<br /><b>VAT nr.:</b> 4350294825</p> */ }
			</Box>
			<Box
				m="0px"
				sx={ {
					borderRadius: 0,
					borderTopRightRadius: !mobile && "inherit",
					borderBottomLeftRadius: mobile && "inherit",
					borderBottomRightRadius: "inherit",
					// overflow: "hidden"
				} }
			>
				<MedImage2 />
			</Box>
		</Box>
	);
};

const FooterBox = (props) => {
	return (
		<Box
			sx={ {
				background: "lightgrey",
				boxSizing: "content-box",
				minHeight: "fit-content",
				width: "100%",
				top: "100%",
				position: "sticky",
			} }
		>
			{ props.children && <h3>{ props.children }</h3> }
			<p style={ { padding: "1em 5%", textAlign: "start" } }>
				<b>
					<u>Registration Info</u>
				</b>
				<br />
				<b>Reg nr.:</b> 2020/489385/07
				<br />
				<b>VAT nr.:</b> 4350294825
			</p>
		</Box>
	);
};

// const ParallaxBox = (props = ({ image, imageString, sx })) => {
// 	// var elem = <props.image style={ { height: '100%', objectFit: 'cover' } } />
// 	return (
// 		<Box
// 			className="parallax"
// 			sx={ { ...props.sx, backgroundImage: `url(${props.imageString})` }
// 			}>
// 			<props.image style={ { height: '100%', objectFit: 'cover' } } />
// 		</Box>

// 	);
// };

const ParallaxBox = (props) => {
	// console.log(`url(${image})`);

	return <Box className="parallax" { ...props.small } sx={ { ...props.sx, height: '100%', objectFit: 'cover' } } />
};

const ProfileBox = (/*{ pusher }*/) => {
	// const { totalQty } = useBasket();

	return (
		<FloatBox right sx={ { order: 3 } }>
			{/* <BasketButton count={totalQty} />
			<ProfileButton /> */}
		</FloatBox>
	);
};

export {
	ContactBox,
	ContentBox,
	FloatBox,
	FooterBox,
	HeaderBox,
	LinkBox,
	ParallaxBox,
	ProfileBox,
	SubHeaderBox,
	TitleBox,
};

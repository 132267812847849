import React from "react";
import { create } from "zustand";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardMedia,
	CardContent,
	CardActions,
	Paper
} from "@mui/material";
import { Alert, HeaderBox, SubHeaderBox } from ".";
import Img from "../Media/DefaultImg.jpg";

const Basket = create((set) => ({
	ProductsData: [],
	BasketProducts: [],
	totalQty: 0,
	totalPrice: 0
}));

const useBasket = Basket;

function ProductCard ({ product }) {
	const { id, title, image, price, basketQty } = product;

	return (
		<Paper elevation={ 3 } sx={ { margin: "5px" } }>
			<Card key={ id } sx={ { display: "flex", height: 150 } }>
				<CardMedia
					component="img"
					image={ image ? image : Img }
					alt={ `${title} image` }
					sx={ { height: "100%", width: "45%" } }
				/>
				<Box sx={ { display: "flex", flexDirection: "column" } }>
					<CardContent
						sx={ { display: "flex", justifyContent: "space-between" } }
					>
						<Box>
							<HeaderBox content={ title } />
							<SubHeaderBox
								content={ `R ${price}.00 ` }
								sx={ { display: "inline" } }
							/>
							<p style={ { display: "inline", font: "menu" } }>
								incl. VAT
							</p>
						</Box>
					</CardContent>
					<Box>
						<CardAction id={ id } Qty={ basketQty } />
						<p
							style={ {
								width: "fit-Content",
								float: "right",
								font: "menu"
							} }
						>{ `SKU:${id}` }</p>
					</Box>
				</Box>
			</Card>
		</Paper>
	);
}

const CardAction = ({ id, Qty }) => {
	return (
		<CardActions>
			<HeaderBox small title="Qty:" />
			<ButtonGroup>
				<SubtractButton id={ id } />
				<QtyButton id={ id } Qty={ Qty } />
				<AddButton id={ id } />
			</ButtonGroup>
		</CardActions>
	);
};

const SubtractButton = ({ id }) => {
	return (
		<Button
			variant="contained"
			onClick={ () => RemoveOneFromBasket(id) }
			sx={ {
				backgroundColor: "#f00a",
				color: "white",
				":hover": { backgroundColor: "#f00" }
			} }
		>
			-
		</Button>
	);
};
const AddButton = ({ id }) => {
	return (
		<Button
			variant="contained"
			onClick={ () => AddOneToBasket(id) }
			sx={ {
				backgroundColor: "#0f0a",
				color: "white",
				":hover": { backgroundColor: "#0f0" }
			} }
		>
			+
		</Button>
	);
};
const QtyButton = ({ id, Qty }) => {
	const handleSetQty = (id) => {
		let qty = Number(prompt("Set Qty", Qty));
		if (qty >= 0) {
			SetBasketQty(id, qty);
			return Alert(
				"Success",
				`Qty successfully updated to ${qty}`,
				"success"
			);
		} else {
			return Alert("Failed", "Failed to update Qty", "error");
		}
	};
	return <Button onClick={ () => handleSetQty(id) }>{ Qty }</Button>;
};

const AddOneToBasket = (id) => {
	const { ProductsData } = useBasket.getState();

	ProductsData.forEach((product) => {
		if (product.id === id) {
			product.basketQty += 1;
			return product;
		} else {
			return product;
		}
	});

	useBasket.setState({
		ProductsData: ProductsData,
		BasketProducts: ProductsData.map((product) =>
			product.basketQty > 0 ? (
				<ProductCard key={ product.id } product={ product } />
			) : null
		),
		totalQty: getBasketQty(ProductsData),
		totalPrice: getBasketPrice(ProductsData)
	});
};
const RemoveOneFromBasket = (id) => {
	const { ProductsData } = useBasket.getState();

	ProductsData.forEach((product) => {
		if (product.id === id) {
			product.basketQty -= 1;
			return product;
		} else {
			return product;
		}
	});

	useBasket.setState({
		ProductsData: ProductsData,
		BasketProducts: ProductsData.map((product) =>
			product.basketQty > 0 ? (
				<ProductCard key={ product.id } product={ product } />
			) : null
		),
		totalQty: getBasketQty(ProductsData),
		totalPrice: getBasketPrice(ProductsData)
	});
};
const SetBasketQty = (id, Qty) => {
	const { ProductsData } = useBasket.getState();

	ProductsData.forEach((product) => {
		if (product.id === id) {
			product.basketQty = Qty;
			return product;
		} else {
			return product;
		}
	});

	useBasket.setState({
		ProductsData: ProductsData,
		BasketProducts: ProductsData.map((product) =>
			product.basketQty > 0 ? (
				<ProductCard key={ product.id } product={ product } />
			) : null
		),
		totalQty: getBasketQty(ProductsData),
		totalPrice: getBasketPrice(ProductsData)
	});
};

const getBasketQty = (data) => {
	let Total = 0;

	for (let i = 0; i < data.length; i++) {
		const Qty = data[i].basketQty;
		Total += Qty;
	}

	return Total;
};
const getBasketPrice = (data) => {
	let Price = 0;

	for (let i = 0; i < data.length; i++) {
		const Qty = data[i].basketQty;
		const price = data[i].price;
		Price += Qty * price;
	}

	return Price;
};

export { useBasket, AddOneToBasket };
